<template>

  <!--  Style Parameters-->
  <div v-if="isStyle">

    <v-row no-gutters class="pa-3 px-0" >
      <v-col
          v-for="mask in CLIPPING_MASKS_ARRAY" :key="mask"
          cols="3"
      >
        <div class="d-flex justify-center">
          <div class="py-2">
            <v-sheet @click="changeMask(mask)" v-ripple class="pointer" height="60" :color="element.config.mask === mask ? wsATTENTION : wsBACKGROUND" :style="GET_CLIPPING_MASK(mask , true)"  >
            </v-sheet>
          </div>
        </div>



      </v-col>
    </v-row>


    <a-parameter-switch
        @input=" element.config.mask_resize = !$event; $emit('input' , this.COPY(element.config))"
        :value="!element.config.mask_resize"
        label="format.resize"
        class="mt-2"
    />
    <v-divider
        :style="`border-color : ${wsBACKGROUND};`"
        style="border-width: 1px"
        class="mt-4"
    />

    <v-expand-transition>
      <div         v-if="element.config.mask === 'svgMaskSquare'">
        <a-parameter-slider

            @change="$emit('input' , config )"
            v-model="element.config.rounded"
            :default="1"
            label="Rounded"
            :value-color="wsACCENT"
            :track-color="wsBACKGROUND"
            min="1"
            max="200"
        />
        <v-divider
            :style="`border-color : ${wsBACKGROUND};`"
            style="border-width: 1px"
            class="mt-4"
        />
      </div>
    </v-expand-transition>

    <color-param-picker
        @input="$emit('input',element.config)"
        v-model="element.config.background_color"
        label="Color"
        class="pt-2"
        divider
    />



  </div>
</template>

<script>
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";
export default {
  name: "avalonTextSettings",
  components : {
    colorParamPicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      config : {}
    }
  },
  computed : {
  },
  methods : {
    changeMask(mask) {
      this.element.config.mask = mask
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config) )
    },
  },
  mounted() {
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>
</style>